export const MenuList = [
    {
        title: "Home",
        url:"/"
    },
    {
        title: "About",
        url:"/about"
    },
    {
        title: "Special",
        url:"/special"
    },
    {
        title: "Menu",
        url:"/menu"
    },
    {
        title: "Contact",
        url:"/contact"
    },
    {
        title: "Login",
        url:"/login"
    }
];