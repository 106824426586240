import React from 'react';
import '../Styles/HomeCategory.css';
import { NavLink } from 'react-router-dom';


const HomeCategoryItem = ({item}) => {
    return (
        <div className="categoryitems">
            <div className="categoryimage">
                <img src={item.img}/>
            </div>            
            < div className="info">
                <h1>{item.title}</h1>
                <NavLink to ="/menu"><button className="btn">View More</button></NavLink>
            </div> 
        </div>
    );
};

export default HomeCategoryItem;
