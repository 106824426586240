import React from 'react';
import '../Styles/toppings.css';

const ChooseYourTopping = () => {
    return (
        <div>
            	
				<main class="page-main">
      
      <div class="page-content">
        <div class="uk-margin-large-top uk-container">
          <div class="pizza-builder">
            <div class="uk-grid" data-uk-grid>
              <div class="uk-width-2-3@m">
                <div class="pizza-builder-steps">
                  <div class="pizza-builder-step">
                    <div class="pizza-builder-step__title">
                      <div class="pizza-builder-step__numb">1</div>
                      <h1 className="topping_title">Choose Your Toppings</h1>
                    </div>
                    <div class="pizza-builder-step__content">
                      <div class="pizza-builder-size-pizza">
                        <ul class="size-pizza-list">
                          <li class="size-pizza-item">
                          	<label class="size-pizza-item__box">
                          		<input type="radio" name="size" />
                          	<span class="size-pizza-item__content"><span class="size-pizza-item__circle small">
                          		<span>9”</span></span>
                          	<span class="size-pizza-item__title">Small</span></span></label></li>
                          <li class="size-pizza-item"><label class="size-pizza-item__box">
							  <input type="radio" name="size" />
								  <span class="size-pizza-item__content" />
								  	<span class="size-pizza-item__circle medium"><span>12”</span>
								  </span>
								  <span class="size-pizza-item__title">Medium</span></label></li>
                          <li class="size-pizza-item"><label class="size-pizza-item__box">
							  <input type="radio" name="size" checked />
								  <span class="size-pizza-item__content" >
								  <span class="size-pizza-item__circle large"><span>14”</span></span><span class="size-pizza-item__title">Large</span></span></label></li>
                          <li class="size-pizza-item"><label class="size-pizza-item__box">
							  <input type="radio" name="size" />
								  <span class="size-pizza-item__content" >
								  <span class="size-pizza-item__circle jumbo">
									  <span>16”</span></span>
								  <span class="size-pizza-item__title">Jumbo</span>
								  </span></label></li>
                          <li class="size-pizza-item">
							  <label class="size-pizza-item__box">
							  <input type="radio" name="size" />
							  <span class="size-pizza-item__content" >
								  <span class="size-pizza-item__circle party"><span>18”</span>
								  </span><span class="size-pizza-item__title">Party Size</span></span>
								  </label>
								  </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="pizza-builder-step">
                    <div class="pizza-builder-step__title">
                      <div class="pizza-builder-step__numb">2</div>
                      <h1 className="topping_title">Select Your Sauce</h1>
                    </div>
                    <div class="pizza-builder-step__content">
                      <div class="pizza-builder-sause">
                        <div class="js-hidden-box sause-list uk-grid uk-child-width-1-3@s uk-child-width-1-2" data-uk-grid>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-1.jpg" alt="souse" /></span><span class="sause-item__title">Ranch</span><span class="sause-item__desc">A cool, perfect blend of herbs and spices.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-2.jpg" alt="souse" /></span><span class="sause-item__title">Blue Cheese</span><span class="sause-item__desc">Creamy blue cheese packed with flavor in every dip.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-3.jpg" alt="souse" /></span><span class="sause-item__title">Marinara</span><span class="sause-item__desc">All the flavor of Italian pizza sauce for dipping.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-4.jpg " alt="souse" /></span><span class="sause-item__title">Garlic</span><span class="sause-item__desc">A cool, perfect blend of herbs and spices.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-5.jpg" alt="souse" /></span><span class="sause-item__title">Barbeque</span><span class="sause-item__desc">Creamy blue cheese packed with flavor in every dip.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-6.jpg" alt="souse" /></span><span class="sause-item__title">Cheese</span><span class="sause-item__desc">All the flavor of Italian pizza sauce for dipping.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-1.jpg" alt="souse" /></span><span class="sause-item__title">Ranch</span><span class="sause-item__desc">A cool, perfect blend of herbs and spices.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-2.jpg" alt="souse" /></span><span class="sause-item__title">Blue Cheese</span><span class="sause-item__desc">Creamy blue cheese packed with flavor in every dip.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-3.jpg" alt="souse" /></span><span class="sause-item__title">Marinara</span><span class="sause-item__desc">All the flavor of Italian pizza sauce for dipping.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-4.jpg" alt="souse" /></span><span class="sause-item__title">Garlic</span><span class="sause-item__desc">A cool, perfect blend of herbs and spices.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-5.jpg" alt="souse" /></span><span class="sause-item__title">Barbeque</span><span class="sause-item__desc">Creamy blue cheese packed with flavor in every dip.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="sause-item">
                              <div class="js-checkbox sause-item__box"><span class="sause-item__content"><span class="sause-item__media"><img src="img/pages/builder/souse-6.jpg" alt="souse" /></span><span class="sause-item__title">Cheese</span><span class="sause-item__desc">All the flavor of Italian pizza sauce for dipping.</span><span class="sause-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="js-hidden-btn uk-text-center uk-margin-medium-top"><button class="btn-more" type="button">View More Options</button></div>
                      </div>
                    </div>
                  </div>
                  <div class="pizza-builder-step">
                    <div class="pizza-builder-step__title">
                      <div class="pizza-builder-step__numb">3</div>
                      <h1 className="topping_title">Add Extra Cheese</h1>
                    </div>
                    <div class="pizza-builder-step__content">
                      <div class="pizza-builder-cheese">
                        <div class="cheese-list uk-grid uk-child-width-1-3@s uk-child-width-1-2" data-uk-grid>
                          <div>
                            <div class="cheese-item">
                              <div class="js-checkbox cheese-item__box"><span class="cheese-item__content"><span class="cheese-item__media"><img src="img/pages/builder/cheese-1.jpg" alt="cheese" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="cheese-item__title">Garlic Cheese</span><span class="cheese-item__desc">Garlic Cheese 80g</span><span class="cheese-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="cheese-item">
                              <div class="js-checkbox cheese-item__box"><span class="cheese-item__content"><span class="cheese-item__media"><img src="img/pages/builder/cheese-2.jpg" alt="cheese" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="cheese-item__title">Apple Cheese</span><span class="cheese-item__desc">Apple Cheese 50g</span><span class="cheese-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pizza-builder-step">
                    <div class="pizza-builder-step__title">
                      <div class="pizza-builder-step__numb">4</div>
                      <h1 className="topping_title">Choose Your Toppings</h1>
                    </div>
                    <div class="pizza-builder-step__content">
                      <p>You can select upto 3 toppings for your order.</p>
                      <div class="pizza-builder-toppings">
                        <div class="js-hidden-box toppings-list uk-grid uk-child-width-1-3@s uk-child-width-1-2" data-uk-grid>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-1.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">Pineapple</span><span class="toppings-item__desc">Pineapple 80g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-2.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">Mushrooms</span><span class="toppings-item__desc">Mushrooms 50g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-3.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">Hot Chillies</span><span class="toppings-item__desc">Hot Chillies 10g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-4.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">Aubergines</span><span class="toppings-item__desc">Aubergines 50g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-5.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">PeppeRs </span><span class="toppings-item__desc">PeppeRs  5g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-2.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">Sweet Corn</span><span class="toppings-item__desc">Sweet Corn 80g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-1.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">Pineapple</span><span class="toppings-item__desc">Pineapple 80g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-2.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">Mushrooms</span><span class="toppings-item__desc">Mushrooms 50g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-3.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">Hot Chillies</span><span class="toppings-item__desc">Hot Chillies 10g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-4.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">Aubergines</span><span class="toppings-item__desc">Aubergines 50g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-5.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">PeppeRs </span><span class="toppings-item__desc">PeppeRs  5g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                          <div>
                            <div class="toppings-item">
                              <div class="js-checkbox toppings-item__box"><span class="toppings-item__content"><span class="toppings-item__media"><img src="img/pages/builder/toppings-2.jpg" alt="toppings" /><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></span><span class="toppings-item__title">Sweet Corn</span><span class="toppings-item__desc">Sweet Corn 80g</span><span class="toppings-item__price">Rs 5.50</span></span></div>
                            </div>
                          </div>
                        </div>
                        <div class="js-hidden-btn uk-text-center uk-margin-medium-top"><button class="btn-more" type="button">View More Options</button></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-width-1-3@m uk-flex-fiRs t@m">
                <div class="pizza-builder-order">
                  <div class="pizza-builder-order__title">Your Order</div>
                  <div class="pizza-builder-order__rows">
                    <div class="pizza-builder-order__row pizza-builder-order__row-title"> <span>Product</span><span>Total</span></div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Meat Board</div>
                      <div class="pizza-builder-order__row-col"><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></div>
                      <div class="pizza-builder-order__row-price">20 s</div>
                    </div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Pineapples</div>
                      <div class="pizza-builder-order__row-col"><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></div>
                      <div class="pizza-builder-order__row-price">35 s</div>
                    </div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Sweet Pepper</div>
                      <div class="pizza-builder-order__row-col"><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></div>
                      <div class="pizza-builder-order__row-price">40 s</div>
                    </div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Mushroom Mix</div>
                      <div class="pizza-builder-order__row-col"><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></div>
                      <div class="pizza-builder-order__row-price">20 s</div>
                    </div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Sausage Salami</div>
                      <div class="pizza-builder-order__row-col"><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></div>
                      <div class="pizza-builder-order__row-price">35 s</div>
                    </div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Fine Becon</div>
                      <div class="pizza-builder-order__row-col"><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></div>
                      <div class="pizza-builder-order__row-price">40 s</div>
                    </div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Sauce "City Pizza"</div>
                      <div class="pizza-builder-order__row-col"><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></div>
                      <div class="pizza-builder-order__row-price">20 s</div>
                    </div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Meat Board</div>
                      <div class="pizza-builder-order__row-col"><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></div>
                      <div class="pizza-builder-order__row-price">20 s</div>
                    </div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Pineapples</div>
                      <div class="pizza-builder-order__row-col"><span class="counter"><span class="minus">-</span><input type="text" value="1" /><span class="plus">+</span></span></div>
                      <div class="pizza-builder-order__row-price">35 s</div>
                    </div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Sweet Pepper</div>
                      <div class="pizza-builder-order__row-col">
                      	<span class="counter">
                      	<span class="minus">-</span>
	                      <input type="text" value="1" />
	                      <span class="plus">+</span>
	                  </span>
                  </div>
                      <div class="pizza-builder-order__row-price">40 s</div>
                    </div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Mushroom Mix</div>
                      <div class="pizza-builder-order__row-col">
                      	<span class="counter"><span class="minus">-</span>
	                      <input type="text" value="1" />
	                      <span class="plus">+</span>
	                  </span>
                  		</div>
                      <div class="pizza-builder-order__row-price">20 s</div>
                    </div>
                    <div class="pizza-builder-order__row">
                      <div class="pizza-builder-order__row-name">Fine Susage</div>
                      <div class="pizza-builder-order__row-col">
                      	<span class="counter"><span class="minus">-</span>
	                      <input type="text" value="1" />
	                      <span class="plus">+</span>
	                  </span>
                  </div>
                      <div class="pizza-builder-order__row-price">55 s</div>
                    </div>
                  </div>
                  <div class="pizza-builder-order__info"> 
                  	<span>Pizza Size:  14” Large</span><span>Rs 6.70</span>
                  </div>
                  <div class="pizza-builder-order__total">
                  	<span>Order Total</span><span>Rs 25.38</span></div>
                  <div class="pizza-builder-order__btns"> 
                  	<button class="uk-button uk-width-1-1" type="button">
                  		<span>Add to Cart</span>
                  		<span data-uk-icon="cart"></span>
                  	</button>
                  	<button class="uk-button uk-button-default uk-width-1-1" type="button">
                  		<span>Add / Create another pizza</span>
                  	</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
				
					{/* <div class="section-title">
						<div class="container">
							<div class="row">
								<div class="col-xs-12">
									<div class="section__inner">
										<h1 class="ui-title-page">Pizza Builder</h1>
										<ol class="breadcrumb">
											<li><a href="home.html">Home</a></li>
											<li class="active">Pizza Builder</li>
										</ol>
									</div>
								</div>
							</div>
						</div>
					</div> */}


					{/* <div class="section_mod-b">
						<form class="pizza-builder" action="post" name="pizza-size">
							<div class="container">
								<div class="row">
									<div class="col-md-8">
										<div class="accordion accordion_mod-b panel-group" id="accordion-1">
											<div class="panel panel-default">
												<div class="panel-heading">
													<a class="btn-collapse" data-toggle="collapse" href="#collapse-1"><i class="icon"></i></a>
													<h3 class="panel-title"><span class="panel-title__number">1.</span>Choose Your Crust’s Size</h3>
												</div>
												<div id="collapse-1" class="panel-collapse collapse in">
													<div class="panel-body">
														<input class="pizza-builder__radio hidden" id="pizza-size_9" type="radio" name="pizza-size" value="9" />
														<label class="pizza-builder__radio-label" for="pizza-size_9">
															<span class="pizza-builder__radio-number pizza-builder__radio-number_9"><span class="pizza-builder__radio-inner">9’’</span></span>
															<span class="pizza-builder__radio-title">Small</span>
														</label>

														<input class="pizza-builder__radio hidden" id="pizza-size_12" type="radio" name="pizza-size" value="12" checked /> 
														<label class="pizza-builder__radio-label" for="pizza-size_12">
															<span class="pizza-builder__radio-number pizza-builder__radio-number_12"><span class="pizza-builder__radio-inner">12’’</span></span>
															<span class="pizza-builder__radio-title">Medium</span>
														</label>

														<input class="pizza-builder__radio hidden" id="pizza-size_14" type="radio" name="pizza-size" value="14" />
														<label class="pizza-builder__radio-label" for="pizza-size_14">
															<span class="pizza-builder__radio-number pizza-builder__radio-number_14"><span class="pizza-builder__radio-inner">14’’</span></span>
															<span class="pizza-builder__radio-title">Large</span>
														</label>

														<input class="pizza-builder__radio hidden" id="pizza-size_16" type="radio" name="pizza-size" value="16" />
														<label class="pizza-builder__radio-label" for="pizza-size_16">
															<span class="pizza-builder__radio-number pizza-builder__radio-number_16"><span class="pizza-builder__radio-inner">16’’</span></span>
															<span class="pizza-builder__radio-title">Jumbo</span>
														</label>

														<input class="pizza-builder__radio hidden" id="pizza-size_18" type="radio" name="pizza-size" value="18" />
														<label class="pizza-builder__radio-label" for="pizza-size_18">
															<span class="pizza-builder__radio-number pizza-builder__radio-number_18"><span class="pizza-builder__radio-inner">18’’</span></span>
															<span class="pizza-builder__radio-title">Party Size</span>
														</label>
													</div>
												</div>
											</div>

											<div class="panel">
												<div class="panel-heading">
													<a class="btn-collapse" data-toggle="collapse" href="#collapse-2"><i class="icon"></i></a>
													<h3 class="panel-title"><span class="panel-title__number">2.</span>Select Your Sauce</h3>
												</div>
												<div id="collapse-2" class="panel-collapse collapse in">
													<div class="panel-body">
														<div class="pizza-builder__wrap-check">
															<input class="pizza-builder__check hidden" id="pizza-sauce_1" type="checkbox" />
															<label class="pizza-builder__item" for="pizza-sauce_1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-ranch.png" height="135" width="135" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Ranch</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">A cool, perfect blend of herbs and spices.</span>
																<span class="pizza-builder__check-price">Rs 1.50</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_2" type="checkbox" checked />
															<label class="pizza-builder__item" for="pizza-sauce_2">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-bluecheese.png" height="136" width="135" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Blue Cheese</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Creamy blue cheese packed with flavor in every dip.</span>
																<span class="pizza-builder__check-price">Rs 0.90</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_3" type="checkbox" />
															<label class="pizza-builder__item" for="pizza-sauce_3">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-marinara.png" height="136" width="135" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Marinara</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">All the flavor of Italian pizza sauce, sized for dipping.</span>
																<span class="pizza-builder__check-price">Rs 1.15</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_4" type="checkbox" /> 
															<label class="pizza-builder__item" for="pizza-sauce_4">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-cheese.png" height="135" width="135" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Cheese</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">A cool, perfect blend of herbs and spices.</span>
																<span class="pizza-builder__check-price">Rs 0.50</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_5" type="checkbox" /> 
															<label class="pizza-builder__item" for="pizza-sauce_5">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-garlic-butter.png" height="136" width="135" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Garlic</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Put a little zip in your dip with fresh garlic.</span>
																<span class="pizza-builder__check-price">Rs 1.50</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_6" type="checkbox" /> 
															<label class="pizza-builder__item" for="pizza-sauce_6">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-marinara.png" height="136" width="135" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Barbeque</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">A smoky barbeque sauce made with honey and molasses.</span>
																<span class="pizza-builder__check-price">Rs 0.65</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_7" type="checkbox" />
															<label class="pizza-builder__item" for="pizza-sauce_7">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-icing.png" height="135" width="135" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Icing</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">A frosty-sweet treat that’s the perfect compliment to pizza.</span>
																<span class="pizza-builder__check-price">Rs 1.30</span>
																<i class="icon-check fa fa-check"></i>
															</label>
														</div>												</div>
												</div>
                                                </div>

											<div class="panel">
												<div class="panel-heading">
													<a class="btn-collapse collapsed" data-toggle="collapse" href="#collapse-3"><i class="icon"></i></a>
													<h3 class="panel-title"><span class="panel-title__number">3.</span>Add Cheese</h3>
												</div>
												<div id="collapse-3" class="panel-collapse collapse">
													<div class="panel-body">
														<div class="pizza-builder__wrap-check">
															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-cheese/1.png" height="133" width="180" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Pineapple</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Pineapple, 60g</span>
																<span class="pizza-builder__check-price">Rs 1.50</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a js-active" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-cheese/2.png" height="133" width="180" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Pineapple</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Pineapple, 60g</span>
																<span class="pizza-builder__check-price">Rs 1.50</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="panel">
												<div class="panel-heading">
													<a class="btn-collapse collapse" data-toggle="collapse" href="#collapse-4"><i class="icon"></i></a>
													<h3 class="panel-title"><span class="panel-title__number">4.</span>Choose Your Toppings</h3>
												</div>
												<div id="collapse-4" class="panel-collapse collapse in">
													<div class="panel-body">
														<div class="pizza-builder__wrap-check">
															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="https://pro-theme.com/html/pizzeria/assets/media/list-toppings/1.png" height="114" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Pineapple</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Pineapple, 60g</span>
																<span class="pizza-builder__check-price">Rs 1.50</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/2.png" height="117" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Mushrooms</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Mushrooms, 110g</span>
																<span class="pizza-builder__check-price">Rs 0.45</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/3.png" height="130" width="172" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Spinach</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Spinach, 35g</span>
																<span class="pizza-builder__check-price">Rs 2.20</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a js-active" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/4.png" height="114" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Olives</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Olives, 80g</span>
																<span class="pizza-builder__check-price">Rs 1.00</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/5.png" height="120" width="166" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Hot Chillies</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Hot Chillies, 10g</span>
																<span class="pizza-builder__check-price">Rs 1.15</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/6.png" height="114" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Tiger Shrimps</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Black tiger shrimps, 90g</span>
																<span class="pizza-builder__check-price">Rs 1.05</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/7.png" height="114" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Onions</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Onions, 50g</span>
																<span class="pizza-builder__check-price">Rs 0.30</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/8.png" height="114" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Chicken</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Chicken, 100g</span>
																<span class="pizza-builder__check-price">Rs 3.60</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/9.png" height="114" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Broccoli</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Broccoli, 70g</span>
																<span class="pizza-builder__check-price">Rs 1.50</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/10.png" height="118" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Salami</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Mushrooms, 95g</span>
																<span class="pizza-builder__check-price">Rs 2.70</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/11.png" height="115" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Aubergines</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Aubergines, 150g</span>
																<span class="pizza-builder__check-price">Rs 1.45</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/12.png" height="121" width="162" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">PeppeRs </span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">PeppeRs , 120g</span>
																<span class="pizza-builder__check-price">Rs 0.80</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/13.png" height="114" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Egg</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Egg, 30g</span>
																<span class="pizza-builder__check-price">Rs 0.25</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/14.png" height="114" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Garlic</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Garlic, 10g</span>
																<span class="pizza-builder__check-price">Rs 0.05</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/15.png" height="114" width="160" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Sweet corn</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Sweet corn, 60g</span>
																<span class="pizza-builder__check-price">Rs 0.60</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/16.png" height="114" width="160" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Bacon</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Bacon, 55g</span>
																<span class="pizza-builder__check-price">Rs 2.85</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/17.png" height="114" width="163" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Ham</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Ham, 65g</span>
																<span class="pizza-builder__check-price">Rs 2.05</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/18.png" height="114" width="159" alt="Foto"/>
																</span>
																<span class="pizza-builder__check-name">Seafood</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Seafood, 130g</span>
																<span class="pizza-builder__check-price">Rs 4.60</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/19.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Tuna</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Tuna, 90g</span>
																<span class="pizza-builder__check-price">Rs 0.60</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/20.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Artichokes</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Artichokes, 75g</span>
																<span class="pizza-builder__check-price">Rs 1.90</span>
																<select class="selectpicker" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col-md-4">
										<section class="section-table-order">
											<h2 class="table-order__title ui-title-inner">Your Order</h2>
											<div class="table-container">
												<table class="table-order">
													<thead>
														<tr>
															<th>PRODUCT</th>
															<th>TOTAL</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Blue Cheese </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">Rs 0.90</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Mozzarella </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">Rs 1.50</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Pineapple </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="2" />
																</div>
															</td>
															<td>
																<span class="table-order__price">Rs 3.00</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Mushrooms </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">Rs 0.45</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Chicken </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">Rs 3.60</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">PeppeRs  </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">Rs 0.80</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Garlic </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">Rs 0.05</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Sweet Corn </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">Rs 0.60</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td class="border-cell">
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Ham </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td class="border-cell">
																<span class="table-order__price">Rs 2.05</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td class="border-cell table-order__base"><b>PIZZA SIZE:</b> Medium 12’’<span class="table-order__size"></span></td>
															<td class="border-cell table-order__base">Rs 8.00</td>
														</tr>
														<tr>
															<td class="table-order__total">Order Total</td>
															<td class="table-order__total">Rs 20.95</td>
														</tr>
													</tbody>
												</table>
											</div>
											<a class="table-order__btn ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add to cart</a>
											<a class="table-order__btn ui-btn ui-btn_mod-a btn-effect" href="javascript:void(0);">add and create another pizza</a>
										</section>
									</div>
								</div>
							</div>
						</form>
					</div> */}

        </div>
    )
}

export default ChooseYourTopping

