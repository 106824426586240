import React from 'react';
import './Footer.css';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import RoomIcon from '@mui/icons-material/Room';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import {NavLink} from 'react-router-dom';

const Footer = () => {
    return (
     
        <footer class="footer">


    <div class="footer-inner clearfix">


        <div class="one-half">

            <h5>About Us</h5>
            <div class="title-block6"></div>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look </p>

           </div>


        <div class="one-fourth">

            <h5>Useful Links</h5>
                <ul>
                    <li> <ArrowRightIcon /> <NavLink to="./"> Home</NavLink></li>
                    <li> <ArrowRightIcon /> <NavLink to="/about">About Me</NavLink></li>
                    <li> <ArrowRightIcon />  <NavLink to="/special">Special</NavLink></li>
                    <li><ArrowRightIcon />  <NavLink to ="/menu">Menu</NavLink></li>
                    <li><ArrowRightIcon />  <NavLink to="/contact">Contact</NavLink></li>
                </ul>
        </div>


        <div class="one-fourth">

            <h5>Contact Details</h5>
            <div class="title-block6"></div>
            <ul class="contact-widget">
                <li class="cw-address"> <RoomIcon /> Daniels Pizza</li>
                <li class="cw-phone"> <LocalPhoneIcon /> 9818109201</li>
            </ul>


        </div>

        <div class="clearboth"></div>


    </div>


    <div class="footer-bottom">

        <div class="footer-social-icons-wrapper">
            <a href="#"><FacebookIcon /></a>
            <a href="#"><TwitterIcon /></a>
            <a href="#"><InstagramIcon /></a>
        </div>

        <p class="footer-message">&copy; 2021 Royale Interactive Inc. All Rights Reserved</p>


    </div>
    </footer>
    
    )
}

export default Footer;
