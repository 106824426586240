//import { Divider } from '@material-ui/core';
import React, { useState } from 'react';
import '../Styles/Special.css';
import pizza from '../images/special/4.jpg';
import Breakfast from '../images/special/3.jpg';
import Desert from '../images/special/2.jpg';
import chickenflorentine from '../images/special/1.jpg';
 




const Special = () => {

  const [status, setStatus] = React.useState (false)
  // const [showResults, setShowResults] = React.useState(false)
   const toggle = () => {setStatus (status => !status)
    // alert(status)

    
  }
  const [statusOne, setStatusOne] = React.useState (false)
  const toggleone = () => {setStatusOne (statusOne => !statusOne)
  }

  const [statusTwo, setStatusTwo] = React.useState (false)
  const toggletwo = () => {setStatusTwo (statusTwo => !statusTwo)
  }

  const [statusThree, setStatusThree] = React.useState (false)
  const togglethree = () => {setStatusThree (statusThree => !statusThree)
  }

  // const toggleone = () => {setStatus (status => !status)}

  const decrement = (props) => {let dec = document.getElementById (props).value
  document.getElementById (props).value = dec-1
    // alert(dec-1)
}

  const increment = (props) => {let inc = parseInt (document.getElementById (props).value)
  document.getElementById (props).value = inc+1
  // alert(inc+1)

}
  // State = { showing: true };

    return (
    // const Showing= this.State

        <div >
          <toggleelement />
          <section class="innerbanner about dark" >
            <div id="service-parallax">
                <div class="bcg background1"
                      data-center="background-position: 50% 0px;"
                      data-bottom-top="background-position: 50% 100px;"
                      data-top-bottom="background-position: 50% -100px;"
                      data-anchor-target="#service-parallax">
                  <div class="bg-transparent">
                      <div class="banner-content">
                          <div class="container" >
                          <div class="slider-content"> <i class="icon-home-ico"></i>
                              <h1 className="innerbanner_title">Special</h1>
                          </div>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
          </section>

          <section className="special_section">
              <div className="special_sec_inner">
                  <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="product-item">
                  <div class="product-item__box">
                    <div class="product-item__intro">
                      <div class="product-item__not-active">
                        <div class="product-item__media">
                          <div class="uk-inline-clip uk-transition-toggle uk-light" data-uk-lightbox="data-uk-lightbox">
                            <a href={pizza}>
                            <img src={pizza} />
                              <div class="uk-transition-fade uk-position-cover uk-overlay uk-overlay-primary"></div>
                              <div class="uk-position-center"><span class="uk-transition-fade uk-icon" data-uk-icon="icon: search;">
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="search">
                                  <circle fill="none" stroke="#000" stroke-width="1.1" cx="9" cy="9" r="7"></circle>
                                  <path fill="none" stroke="#000" stroke-width="1.1" d="M14,14 L18,18 L14,14 Z"></path></svg></span></div>
                            </a></div>
                          <div class="product-item__whish"><svg class="svg-inline--fa fa-heart fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg></div>
                          <div class="product-item__type spicy"></div>
                          <div class="product-item__tooltip" data-uk-tooltip="title: spicy pizza; pos: bottom-right" title="" aria-expanded="false" tabindex="0"><svg class="svg-inline--fa fa-info-circle fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg></div>
                        </div>
                        <div class="product-item__title"><a href="page-product.html">Creamy Melt Pizza</a></div>
                        <div class="product-item__desc">Spicy Cheese pizza with topping of beef pepperoni &amp; sauce</div>
                        <div class="product-item__select">
                          <div class="select-box select-box--thickness">
                            <ul>
                              <li><label><input type="radio" name="thickness-1" checked="checked" />
                              <span>Thin</span></label></li>
                              <li><label><input type="radio" name="thickness-1" />
                              <span>Normal</span></label></li>
                              <li><label><input type="radio" name="thickness-1" />
                              <span>Thick</span></label></li>
                            </ul>
                          </div>
                          <div class="select-box select-box--size">
                            <ul>
                              <li><label><input type="radio" name="size-1" />
                              <span>7 “</span></label></li>
                              <li><label><input type="radio" name="size-1" />
                              <span>9 “</span></label></li>
                              <li><label><input type="radio" name="size-1" checked="checked" />
                              <span>11 “</span></label></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      


                  <div className="toggleproductclass" >
                    {
                    status?
                    <div class="product-item__active">
                        <div class="title">Add Ingredients</div>
                        <div class="row">
                          <div class="name">Meat Board</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={ () => decrement('meat')} >-</span>
                          <input type="text" value="3" id="meat" />
                            <span class="plus" onClick={ () => increment('meat')}>+</span></span></div>
                          {/* <div class="price">20 s</div> */}
                        </div>
                        <div class="row">
                          <div class="name">Pineapples</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('pineapple')}>-</span>
                          <input type="text" value="1" id="pineapple"/>
                            <span class="plus" onClick={ () => increment('pineapple')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sweet Pepper</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('sweetpepper')}>-</span>
                          <input type="text" value="1" id="sweetpepper" />
                          <span class="plus" onClick={ () => increment('sweetpepper')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Mushroom Mix</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('mushroommix')}>-</span>
                          <input type="text" value="1" id="mushroommix" />
                          <span class="plus" onClick={ () => increment('mushroommix')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sausage Salami</div>
                          <div class="col"><span class="counter"  ><span class="minus" onClick={() => decrement ('sausagesalami')}>-</span>
                          <input type="text" value="1" id="sausagesalami" />
                          <span class="plus" onClick={ () => increment('sausagesalami')} >+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Fine Becon</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('finebecon')}>-</span>
                          <input type="text" value="1"  id="finebecon" />
                          <span class="plus"  onClick={ () => increment('finebecon')} >+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sauce "City Pizza"</div>
                          <div class="col"><span class="counter" ><span class="minus" onClick={() => decrement ('sause')}>-</span>
                          <input type="text" value="1" id="sause"  />
                            <span class="plus" onClick={ () => increment('sause')}> +</span></span></div>
                          
                        </div>
                        
                      </div>
                    
                    : null}
                    </div>
                  </div>
                    <div class="product-item__toggle"> 
                      {/* <button type="button" onClick={() => setStatus (!status)}> 
                        <span>Additional Ingredients</span>
                      </button>    */}

                      <button type="button" onClick={toggle}> 
                        <span>Additional Ingredients</span>
                      </button>                    
                    </div>





                    <div class="product-item__info">
                      <div class="product-item__price"> <span>Price: </span><span class="value">Rs 14.99</span></div>
                      <div class="product-item__addcart"> <a class="uk-button uk-button-default" href="page-product.html">Add to Cart<span data-uk-icon="cart" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="cart"><circle cx="7.3" cy="17.3" r="1.4"></circle><circle cx="13.3" cy="17.3" r="1.4"></circle><polyline fill="none" stroke="#000" points="0 2 3.2 4 5.3 12.5 16 12.5 18 6.5 8 6.5"></polyline></svg></span></a></div>
                    </div>
                  </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="product-item">
                  <div class="product-item__box">
                    <div class="product-item__intro">
                      <div class="product-item__not-active">
                        <div class="product-item__media">
                          <div class="uk-inline-clip uk-transition-toggle uk-light" data-uk-lightbox="data-uk-lightbox">
                            <a href={Breakfast}>
                            <img src={Breakfast} alt="Creamy Melt Pizza" />
                              <div class="uk-transition-fade uk-position-cover uk-overlay uk-overlay-primary"></div>
                              <div class="uk-position-center"><span class="uk-transition-fade uk-icon" data-uk-icon="icon: search;">
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="search">
                                  <circle fill="none" stroke="#000" stroke-width="1.1" cx="9" cy="9" r="7"></circle>
                                  <path fill="none" stroke="#000" stroke-width="1.1" d="M14,14 L18,18 L14,14 Z"></path></svg></span></div>
                            </a></div>
                          <div class="product-item__whish"><svg class="svg-inline--fa fa-heart fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg></div>
                          <div class="product-item__type spicy"></div>
                          <div class="product-item__tooltip" data-uk-tooltip="title: spicy pizza; pos: bottom-right" title="" aria-expanded="false" tabindex="0"><svg class="svg-inline--fa fa-info-circle fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg></div>
                        </div>
                        <div class="product-item__title"><a href="page-product.html">Creamy Melt Pizza</a></div>
                        <div class="product-item__desc">Spicy Cheese pizza with topping of beef pepperoni &amp; sauce</div>
                        <div class="product-item__select">
                          <div class="select-box select-box--thickness">
                            <ul>
                              <li><label><input type="radio" name="thickness-1" checked="checked" />
                              <span>Thin</span></label></li>
                              <li><label><input type="radio" name="thickness-1" />
                              <span>Normal</span></label></li>
                              <li><label><input type="radio" name="thickness-1" />
                              <span>Thick</span></label></li>
                            </ul>
                          </div>
                          <div class="select-box select-box--size">
                            <ul>
                              <li><label><input type="radio" name="size-1" />
                              <span>7 “</span></label></li>
                              <li><label><input type="radio" name="size-1" />
                              <span>9 “</span></label></li>
                              <li><label><input type="radio" name="size-1" checked="checked" />
                              <span>11 “</span></label></li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="toggleproductclass" >
                    {
                    statusOne?
                    <div class="product-item__active">
                        <div class="title">Add Ingredients</div>
                        <div class="row">
                          <div class="name">Meat Board</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={ () => decrement('meat3')} >-</span>
                          <input type="text" value="3" id="meat3" />
                            <span class="plus" onClick={ () => increment('meat3')}>+</span></span></div>
                          {/* <div class="price">20 s</div> */}
                        </div>
                        <div class="row">
                          <div class="name">Pineapples</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('pineapple3')}>-</span>
                          <input type="text" value="1" id="pineapple3"/>
                            <span class="plus" onClick={ () => increment('pineapple3')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sweet Pepper</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('sweetpepper3')}>-</span>
                          <input type="text" value="1" id="sweetpepper3" />
                          <span class="plus" onClick={ () => increment('sweetpepper3')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Mushroom Mix</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('mushroommix3')}>-</span>
                          <input type="text" value="1" id="mushroommix3" />
                          <span class="plus" onClick={ () => increment('mushroommix3')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sausage Salami</div>
                          <div class="col"><span class="counter"  ><span class="minus" onClick={() => decrement ('sausagesalami3')}>-</span>
                          <input type="text" value="1" id="sausagesalami3" />
                          <span class="plus" onClick={ () => increment('sausagesalami3')} >+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Fine Becon</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('finebecon3')}>-</span>
                          <input type="text" value="1"  id="finebecon3" />
                          <span class="plus"  onClick={ () => increment('finebecon3')} >+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sauce "City Pizza"</div>
                          <div class="col"><span class="counter" ><span class="minus" onClick={() => decrement ('sause3')}>-</span>
                          <input type="text" value="1" id="sause3"  />
                            <span class="plus" onClick={ () => increment('sause3')}> +</span></span></div>
                          
                        </div>
                        
                      </div>
                    
                    : null}
                    </div>
                  </div>
                    <div class="product-item__toggle"> 
                      {/* <button type="button" onClick={() => setStatus (!status)}> 
                        <span>Additional Ingredients</span>
                      </button>    */}

                      <button type="button" onClick={toggleone}> 
                        <span>Additional Ingredients</span>
                      </button>                    
                    </div>


                    <div class="product-item__info">
                      <div class="product-item__price"> <span>Price: </span><span class="value">Rs 14.99</span></div>
                      <div class="product-item__addcart"> <a class="uk-button uk-button-default" href="page-product.html">Add to Cart<span data-uk-icon="cart" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="cart"><circle cx="7.3" cy="17.3" r="1.4"></circle><circle cx="13.3" cy="17.3" r="1.4"></circle><polyline fill="none" stroke="#000" points="0 2 3.2 4 5.3 12.5 16 12.5 18 6.5 8 6.5"></polyline></svg></span></a></div>
                    </div>
                  </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="product-item">
                  <div class="product-item__box">
                    <div class="product-item__intro">
                      <div class="product-item__not-active">
                        <div class="product-item__media">
                          <div class="uk-inline-clip uk-transition-toggle uk-light" data-uk-lightbox="data-uk-lightbox">
                            <a href={chickenflorentine}>
                            <img src={chickenflorentine} alt="Creamy Melt Pizza" />
                              <div class="uk-transition-fade uk-position-cover uk-overlay uk-overlay-primary"></div>
                              <div class="uk-position-center"><span class="uk-transition-fade uk-icon" data-uk-icon="icon: search;">
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="search">
                                  <circle fill="none" stroke="#000" stroke-width="1.1" cx="9" cy="9" r="7"></circle>
                                  <path fill="none" stroke="#000" stroke-width="1.1" d="M14,14 L18,18 L14,14 Z"></path></svg></span></div>
                            </a></div>
                          <div class="product-item__whish"><svg class="svg-inline--fa fa-heart fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg></div>
                          <div class="product-item__type spicy"></div>
                          <div class="product-item__tooltip" data-uk-tooltip="title: spicy pizza; pos: bottom-right" title="" aria-expanded="false" tabindex="0"><svg class="svg-inline--fa fa-info-circle fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg></div>
                        </div>
                        <div class="product-item__title"><a href="page-product.html">Creamy Melt Pizza</a></div>
                        <div class="product-item__desc">Spicy Cheese pizza with topping of beef pepperoni &amp; sauce</div>
                        <div class="product-item__select">
                          <div class="select-box select-box--thickness">
                            <ul>
                              <li><label><input type="radio" name="thickness-1" checked="checked" />
                              <span>Thin</span></label></li>
                              <li><label><input type="radio" name="thickness-1" />
                              <span>Normal</span></label></li>
                              <li><label><input type="radio" name="thickness-1" />
                              <span>Thick</span></label></li>
                            </ul>
                          </div>
                          <div class="select-box select-box--size">
                            <ul>
                              <li><label><input type="radio" name="size-1" />
                              <span>7 “</span></label></li>
                              <li><label><input type="radio" name="size-1" />
                              <span>9 “</span></label></li>
                              <li><label><input type="radio" name="size-1" checked="checked" />
                              <span>11 “</span></label></li>
                            </ul>
                          </div>
                        </div>
                      </div>

                      <div className="toggleproductclass" >
                    {
                    statusTwo?
                    <div class="product-item__active">
                        <div class="title">Add Ingredients</div>
                        <div class="row">
                          <div class="name">Meat Board</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={ () => decrement('meat2')} >-</span>
                          <input type="text" value="3" id="meat2" />
                            <span class="plus" onClick={ () => increment('meat2')}>+</span></span></div>
                          {/* <div class="price">20 s</div> */}
                        </div>
                        <div class="row">
                          <div class="name">Pineapples</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('pineapple2')}>-</span>
                          <input type="text" value="1" id="pineapple2"/>
                            <span class="plus" onClick={ () => increment('pineapple2')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sweet Pepper</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('sweetpepper2')}>-</span>
                          <input type="text" value="1" id="sweetpepper2" />
                          <span class="plus" onClick={ () => increment('sweetpepper2')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Mushroom Mix</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('mushroommix2')}>-</span>
                          <input type="text" value="1" id="mushroommix2" />
                          <span class="plus" onClick={ () => increment('mushroommix2')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sausage Salami</div>
                          <div class="col"><span class="counter"  ><span class="minus" onClick={() => decrement ('sausagesalami2')}>-</span>
                          <input type="text" value="1" id="sausagesalami2" />
                          <span class="plus" onClick={ () => increment('sausagesalami2')} >+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Fine Becon</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('finebecon2')}>-</span>
                          <input type="text" value="1"  id="finebecon2" />
                          <span class="plus"  onClick={ () => increment('finebecon2')} >+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sauce "City Pizza"</div>
                          <div class="col"><span class="counter" ><span class="minus" onClick={() => decrement ('sause2')}>-</span>
                          <input type="text" value="1" id="sause2"  />
                            <span class="plus" onClick={ () => increment('sause2')}> +</span></span></div>
                          
                        </div>
                        
                      </div>
                    
                    : null}
                    </div>
                  </div>
                    <div class="product-item__toggle"> 
                      {/* <button type="button" onClick={() => setStatus (!status)}> 
                        <span>Additional Ingredients</span>
                      </button>    */}

                      <button type="button" onClick={toggletwo}> 
                        <span>Additional Ingredients</span>
                      </button>                    
                    </div>


                    <div class="product-item__info">
                      <div class="product-item__price"> <span>Price: </span><span class="value">Rs 14.99</span></div>
                      <div class="product-item__addcart"> <a class="uk-button uk-button-default" href="page-product.html">Add to Cart<span data-uk-icon="cart" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="cart"><circle cx="7.3" cy="17.3" r="1.4"></circle><circle cx="13.3" cy="17.3" r="1.4"></circle><polyline fill="none" stroke="#000" points="0 2 3.2 4 5.3 12.5 16 12.5 18 6.5 8 6.5"></polyline></svg></span></a></div>
                    </div>
                  </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
              <div class="product-item">
                  <div class="product-item__box">
                    <div class="product-item__intro">
                      <div class="product-item__not-active">
                        <div class="product-item__media">
                          <div class="uk-inline-clip uk-transition-toggle uk-light" data-uk-lightbox="data-uk-lightbox">
                            <a href={Desert}>
                            <img src={Desert} alt="Creamy Melt Pizza" />
                              <div class="uk-transition-fade uk-position-cover uk-overlay uk-overlay-primary"></div>
                              <div class="uk-position-center"><span class="uk-transition-fade uk-icon" data-uk-icon="icon: search;">
                                <svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="search">
                                  <circle fill="none" stroke="#000" stroke-width="1.1" cx="9" cy="9" r="7"></circle>
                                  <path fill="none" stroke="#000" stroke-width="1.1" d="M14,14 L18,18 L14,14 Z"></path></svg></span></div>
                            </a></div>
                          <div class="product-item__whish"><svg class="svg-inline--fa fa-heart fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg></div>
                          <div class="product-item__type spicy"></div>
                          <div class="product-item__tooltip" data-uk-tooltip="title: spicy pizza; pos: bottom-right" title="" aria-expanded="false" tabindex="0"><svg class="svg-inline--fa fa-info-circle fa-w-16" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
                            <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path></svg></div>
                        </div>
                        <div class="product-item__title"><a href="page-product.html">Creamy Melt Pizza</a></div>
                        <div class="product-item__desc">Spicy Cheese pizza with topping of beef pepperoni &amp; sauce</div>
                        <div class="product-item__select">
                          <div class="select-box select-box--thickness">
                            <ul>
                              <li><label><input type="radio" name="thickness-1" checked="checked" />
                              <span>Thin</span></label></li>
                              <li><label><input type="radio" name="thickness-1" />
                              <span>Normal</span></label></li>
                              <li><label><input type="radio" name="thickness-1" />
                              <span>Thick</span></label></li>
                            </ul>
                          </div>
                          <div class="select-box select-box--size">
                            <ul>
                              <li><label><input type="radio" name="size-1" />
                              <span>7 “</span></label></li>
                              <li><label><input type="radio" name="size-1" />
                              <span>9 “</span></label></li>
                              <li><label><input type="radio" name="size-1" checked="checked" />
                              <span>11 “</span></label></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      
                      <div className="toggleproductclass" >
                    {
                    statusThree?
                    <div class="product-item__active">
                        <div class="title">Add Ingredients</div>
                        <div class="row">
                          <div class="name">Meat Board</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={ () => decrement('meat3')} >-</span>
                          <input type="text" value="3" id="meat3" />
                            <span class="plus" onClick={ () => increment('meat3')}>+</span></span></div>
                          {/* <div class="price">20 s</div> */}
                        </div>
                        <div class="row">
                          <div class="name">Pineapples</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('pineapple3')}>-</span>
                          <input type="text" value="1" id="pineapple3"/>
                            <span class="plus" onClick={ () => increment('pineapple3')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sweet Pepper</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('sweetpepper3')}>-</span>
                          <input type="text" value="1" id="sweetpepper3" />
                          <span class="plus" onClick={ () => increment('sweetpepper3')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Mushroom Mix</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('mushroommix3')}>-</span>
                          <input type="text" value="1" id="mushroommix3" />
                          <span class="plus" onClick={ () => increment('mushroommix3')}>+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sausage Salami</div>
                          <div class="col"><span class="counter"  ><span class="minus" onClick={() => decrement ('sausagesalami3')}>-</span>
                          <input type="text" value="1" id="sausagesalami3" />
                          <span class="plus" onClick={ () => increment('sausagesalami3')} >+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Fine Becon</div>
                          <div class="col"><span class="counter"><span class="minus" onClick={() => decrement ('finebecon3')}>-</span>
                          <input type="text" value="1"  id="finebecon3" />
                          <span class="plus"  onClick={ () => increment('finebecon3')} >+</span></span></div>
                          
                        </div>
                        <div class="row">
                          <div class="name">Sauce "City Pizza"</div>
                          <div class="col"><span class="counter" ><span class="minus" onClick={() => decrement ('sause3')}>-</span>
                          <input type="text" value="1" id="sause3"  />
                            <span class="plus" onClick={ () => increment('sause3')}> +</span></span></div>
                          
                        </div>
                        
                      </div>
                    
                    : null}
                    </div>
                  </div>
                    <div class="product-item__toggle"> 
                      {/* <button type="button" onClick={() => setStatus (!status)}> 
                        <span>Additional Ingredients</span>
                      </button>    */}

                      <button type="button" onClick={togglethree}> 
                        <span>Additional Ingredients</span>
                      </button>                    
                    </div>

                    <div class="product-item__info">
                      <div class="product-item__price"> <span>Price: </span><span class="value">Rs 14.99</span></div>
                      <div class="product-item__addcart"> <a class="uk-button uk-button-default" href="page-product.html">Add to Cart<span data-uk-icon="cart" class="uk-icon"><svg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="cart"><circle cx="7.3" cy="17.3" r="1.4"></circle><circle cx="13.3" cy="17.3" r="1.4"></circle><polyline fill="none" stroke="#000" points="0 2 3.2 4 5.3 12.5 16 12.5 18 6.5 8 6.5"></polyline></svg></span></a></div>
                    </div>
                  </div>
                </div>
            </div>
                  </div>
              </div>
          </section>
        </div>
    )
}

export default Special;
