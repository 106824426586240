import React from 'react';
import '../Styles/Service.css';
import services from '../images/services.png';
import Delivery from '../images/delivery.png';

const Service = () => {
    return (
        <div>
            <div class="services-section container-fluid no-padding">
			<div class="section-padding"></div>
			
			<div class="container service">
				
				<div class="section-header">
					<h1 class="heading service_sec">Services</h1>
					
				</div>
				<div class="col-md-3 col-sm-6 services-content-left">
					<div class="services-box">
						<i class="icon icon-User"></i>
						<h3>SMILING SERVICES</h3>
						<p>The one day when lady met fellow and they knew it was much more than a hunch the first mate and his Skipper.</p>
					</div>
					<div class="services-box">
						<i class="icon icon-Icecream2"></i>
						<h3>BEST QUALITY</h3>
						<p>The one day when lady met fellow and they knew it was much more than a hunch the first mate and his Skipper.</p>
					</div>
					<div class="services-box">
						<i class="icon icon-Layers"></i>
						<h3>GLOBAL FLAVOURS</h3>
						<p>The one day when lady met fellow and they knew it was much more than a hunch the first mate and his Skipper.</p>
					</div>
				</div>
				
				<div class="col-md-3 col-sm-6 services-content-right">
					<div class="services-box">
						<i class="icon icon-Planet"></i>
						<h3>GREAT ATMOSHPERE</h3>
						<p>The one day when lady met fellow and they knew it was much more than a hunch the first mate and his Skipper.</p>
					</div>
					<div class="services-box">
						<i class="icon icon-Pizza"></i>
						<h3>FRESHLY MADE</h3>
						<p>The one day when lady met fellow and they knew it was much more than a hunch the first mate and his Skipper.</p>
					</div>
					<div class="services-box">
						<i class="icon icon-Drop"></i>
						<h3>NATURAL INGREDIENTS</h3>
						<p>The one day when lady met fellow and they knew it was much more than a hunch the first mate and his Skipper.</p>
					</div>
				</div>
				
				<div class="col-md-6 col-sm-12 services-content-center">
					<img src={services} alt="services"  />
				</div>
			</div>


            <div class="delivery-section container-fluid no-padding">
			
			<div class="container">
				<div class="col-md-5 col-sm-5 col-xs-5 delivery-img">
					<img src={Delivery} alt="delivery"  />
				</div>
				<div class="col-md-7 col-sm-7 col-xs-7 delivery-content">
					
					<div class="section-header left-header">
						<h3>Door Step Delivery</h3>
						<p>The one day when the lady met this fellow and they knew it was much more than a hunch the first mate and his Skipper too will do their comfortable.</p>
					</div>
					<div class="delivery-contact">
						<a href="#" title="Order Online" class="order-online">Order Online</a>
						<p><span>(Or)</span>order through one call: <a href="tel:981-810-9201" title="9818109201">9818109201</a></p>
					</div>
				</div>

			</div>

		</div>
            
        </div>
    </div>
    )
}

export default Service;
