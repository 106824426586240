import React from 'react';
//import InnerBanner from '../components/InnerBanner';
import '../Styles/About.css';
import Aboutimage from '../images/about/about.jpg';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import LunchDiningIcon from '@mui/icons-material/LunchDining';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import IcecreamIcon from '@mui/icons-material/Icecream';
import { NavLink } from 'react-router-dom';

const About = () => {
    return (
        <div>
        <section class="innerbanner about dark" >
            <div id="service-parallax">
            <div class="bcg background1"
                        data-center="background-position: 50% 0px;"
                        data-bottom-top="background-position: 50% 100px;"
                        data-top-bottom="background-position: 50% -100px;"
                        data-anchor-target="#service-parallax"
                    >
                <div class="bg-transparent">
                <div class="banner-content">
                    <div class="container" >
                    <div class="slider-content"> <i class="icon-home-ico"></i>
                        <h1>About Us</h1>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </section>

        <section id="intro01" class="padding-100 intro2_01">
        <div class="about_sec">
        <div class="row">
            <div class="col-md-6"> 
            <img class="img-responsive" src={Aboutimage} alt="" /> </div>
            <div class="col-md-5 text-center about_content intro_message mt40"> 
            
            <div class="head_title">
                <i class="icon-intro"></i>
                <h1>About Us</h1>
                <span class="welcome">Welcome to Daniels Pizza</span>
            </div>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like </p>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like </p>
            </div>
        </div>
        </div>
        </section>

        <section id="slide2-02" class="date-blocks dark text-center">
      <div class="bcg aboutbg"
        data-center="background-position: 50% 0px;"
        data-bottom-top="background-position: 50% 100px;"
        data-top-bottom="background-position: 50% -100px;"
        data-anchor-target="#slide2-02"
    >
        <div class="bg-transparent padding-100">
          <div class="about_service">
            <div class="row">
              <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="block-item">
                <FreeBreakfastIcon />
                  <h2>BREAKFAST</h2>
                  <p>6.00 am 10.00 am</p>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="block-item"> 
                <LunchDiningIcon />
                  <h2>LUNCH</h2>
                  <p>10.00 am 2.00 pm</p>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="block-item">
                     <DinnerDiningIcon />
                  <h2>DINNER</h2>
                  <p>4.00 pm 12.00 pm</p>
                </div>
              </div>
              <div class="col-md-3 col-sm-6 col-xs-12">
                <div class="block-item">
                     <IcecreamIcon />
                  <h2>dessert</h2>
                  <p>All Day</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
       
    <section class="extra_touch padding-100 text-center">
      <div class="container">
        <div class="getintouch">
          <h2>Come & <span>Experiences</span> our best of world class cuisine</h2>
          <NavLink to="/contact" class="btn ">GET IN TOUCH</NavLink> </div>
      </div>
    </section>
        </div>
    )
}

export default About;
