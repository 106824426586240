import React from 'react';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PersonIcon from '@material-ui/icons/Person';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import FastfoodIcon from '@material-ui/icons/Fastfood';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {NavLink} from 'react-router-dom';

import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import '../Styles/Banner.css';


const Banner = () => {
    return (
<div className="banner">
        <div className="page-heading">
            {/* <h1>
            Home
            </h1> */}
        <div id="gkHeader">
             <div id="gkHeaderNav">
            <div class="gkPage">
                <a href="" id="gkLogoSmall" class="cssLogo">Daniels Pizza</a>
                <div id="gkMainMenu" class="gkMenuClassic">
                    <div id="gkMainMenuLeft">
                        <nav class="gkMainMenu gkMenu">
                            <ul class="gkmenu level0">
                                <li  class="first active"><NavLink to="/"  class=" first active" id="menu640" title=" Home Menu Item" >Home</NavLink></li>
                                <li  class="last"><NavLink to="/menu"  class=" last" id="menu1028"  >Menu</NavLink></li></ul>
                        </nav> 
                    </div>

                    <div id="gkMainMenuRight">
                        <nav class="gkMainMenu gkMenu">
                            <ul class="gkmenu level0">
                                <li  class="first"><NavLink to="/contact"  class=" first" id="menu426"  >Contact</NavLink></li>
                                <li ><NavLink to="/about"  id="menu663"  >About</NavLink></li>
                                <li  class="last"><NavLink to="#/order" target="_blank"  class=" last" id="menu1120"  >Order Online</NavLink></li>
                                </ul>
                        </nav>
                    </div>
                </div>

                <div id="gkMobileMenu" class="gkPage">
                    <i id="static-aside-menu-toggler" class="fa fa-bars"></i>
                </div>
            </div>
        </div>

        <div id="gkHeaderMod">
            <div class="gkPage">
                {/* <NavLink to="/" id="gkLogo" class="cssLogo" alt="Daniels Pizza"></NavLink> */}
                <div class="custom ">
                    <h1 data-scroll-reveal="enter top over .5s after 0s">
                        <span class="back">Daniels</span> Pizza
                    </h1>
                    <h2 data-scroll-reveal="enter bottom over .5s after .15s">
                        <p className="Delivery">Free Home Delivery </p>
                        <p class="order"><a href="tel:981-810-7201"> Order Now : 9818107201</a></p>
                    </h2>
                    <p className="pickup_delivery">
                        <a href="#restaurant" class="btn" data-scroll-reveal="enter bottom over .5s after .25s">
                            <LocalShippingIcon />
                         <span className="picktext">Delivery</span> <span className="connector">
                             <span class="connect">OR </span> < DeliveryDiningIcon /> </span> 
                             <span className="picktext">PickUp </span></a>
                    </p>
                    <ul class="gk-short-menu">
                        <li data-scroll-reveal="enter bottom over .5s after .25s">
                            <NavLink to="/menu">
                                <FastfoodIcon />
                                <span>Menu</span>
                             </NavLink>
                        </li>
                        <li data-scroll-reveal="enter bottom over .5s after .5s">
                            <NavLink to="/special">
                                <RestaurantIcon />
                                <span>Specials</span>
                            </NavLink>
                        </li>
                        <li data-scroll-reveal="enter bottom over .5s after .75s">
                            <NavLink to="/about">
                                <PersonIcon />
                                 <span>About Us</span>
                            </NavLink>
                        </li>
                        <li data-scroll-reveal="enter bottom over .5s after 1s">
                            <NavLink to="/contact">
                                <AddIcCallIcon />
                                <span>Contact</span>
                            </NavLink>
                        </li>
                        <li data-scroll-reveal="enter bottom over .5s after 1.25s">
                            <NavLink to="/location">
                                <LocationOnIcon />
                                <span>Location</span>
                            </NavLink>
                        </li>
                        <li data-scroll-reveal="enter bottom over .5s after 1.5s">
                            <NavLink to="/order">
                                <ShoppingCartIcon />
                                <span>Order Online</span> 
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        </div>
        </div>
        </div>


)
}
export default Banner;