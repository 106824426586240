import React from 'react';
import { NavLink } from 'react-router-dom';
import '../Styles/GetInTouch.css';

const GetInTouch = () => {
    return (
        <div>
            <section class="extra_touch padding-100 text-center">
      <div class="container">
        <div class="getintouch">
          <h2>Come & <span>Experiences</span> our best of world class cuisine</h2>
          <NavLink to="/contact" class="btn ">GET IN TOUCH</NavLink> </div>
      </div>
    </section>
        </div>
    )
}

export default GetInTouch;
