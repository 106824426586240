import Pizza from '../images/menu/pizzamenia.jpeg';
import Desert from '../images/menu/chocolavacake.jpg';
import Braverages from '../images/menu/margherita.jpg';

import vegPizza from '../images/menu/breakfast_1.jpg';
import pineapplePizza from '../images/menu/pineapple.jpg';
import vegan from '../images/menu/vegan.jpg';
import Vegpizza from '../images/menu/vegpizza.jpg';
import NonvegPizza from '../images/menu/nonvegpizza.jpg';
import NonvegPizza1 from '../images/menu/nonveg1.jpg';
import chocolateBrownie from '../images/menu/chocolatebrownie.jpg';
import chocolavaCake from '../images/menu/chocolavacake.jpg';
import chickenFlorentine from '../images/menu/chickenflorentine.jpg';
import baileyScookies  from '../images/menu/baileyscookies.jpg';
import bbqPizza from '../images/menu/bbqpizza.jpg';

export const categories = [
    {
        id: 1,
        img: Pizza,
        title: "pizza",
    },
    {
        id: 3,
        img: Desert,
        title: "dessert",
    },
    {
        id: 1,
        img: Braverages,
        title: "braverages",
    },

];

export const classicPizzaList = [
        {
            id: 1,
            img: Pizza,
            title: "pizza",
            desc: "lorem ipsum",
        },
        {
            id: 2,
            img: Pizza,
            title: "pizza Menia",
        },
        {
            id: 3,
            img: Pizza,
            title: "pizza Sause",
        },
        {
            id: 4,
            img: Pizza,
            title: " Chicken pizza",
        },
        {
            id: 5,
            img: Pizza,
            title: "pizza",
        },
        {
            id: 6,
            img: Pizza,
            title: "pizza",
        },
];

export const sideOrders = [
    {
        id: 1,
        img: Pizza,
        title: "Veg pizza",
        desc: "lorem ipsum",
    },
    {
        id: 2,
        img: Pizza,
        title: "pizza Menia",
    },
    {
        id: 3,
        img: Pizza,
        title: "Cheese Pizza",
    },
    {
        id: 4,
        img: Pizza,
        title: " Chicken pizza",
    },
    {
        id: 5,
        img: Pizza,
        title: "pizza",
    },
    {
        id: 6,
        img: Pizza,
        title: "pizza",
    },
];

const menu = [
    {
      id: 1,
      title: 'Veg Pizza',
      category: 'VEG PIZZA',
      price: 15.99,
      img: vegPizza,
      desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
      button: 'Order Now',
    },
    
    {
      id: 2,
      title: 'Pineapple Pizza',
      category: 'VEG PIZZA',
      price: 13.99,
      img: pineapplePizza,
      desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
      button: 'Order Now',
    },
    {
      id: 3,
      title: 'Vegan',
      category: 'VEG PIZZA',
      price: 6.99,
      img: vegan,
      desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
      button: 'Order Now',
    },
    {
      id: 4,
      title: 'Veg Pizza',
      category: 'VEG PIZZA',
      price: 20.99,
      img: Vegpizza,
      desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
      button: 'Order Now',
    },
    {
      id: 5,
      title: 'Chicken Pizza',
      category: 'NON-VEG PIZZA',
      price: 22.99,
      img:NonvegPizza,
      desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
      button: 'Order Now',
    },
    {
      id: 6,
      title: 'BBQ Meat Pizza',
      category: 'NON-VEG PIZZA',
      price: 18.99,
      img: bbqPizza,
      desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
      button: 'Order Now',
    },
    {
      id: 7,
      title: 'Non veg',
      category: 'NON-VEG PIZZA',
      price: 8.99,
      img: NonvegPizza1,
      desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
      button: 'Order Now',
    },
    {
      id: 8,
      title: 'chocolate brownie',
      category: 'DESSERTS',
      price: 12.99,
      img: chocolateBrownie,
      desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
      button: 'Order Now',
    },
    {
      id: 9,
      title: 'Choco lava cake',
      category: 'DESSERTS',
      price: 16.99,
      img: chocolavaCake,
      desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
      button: 'Order Now',
    },
    
    {
      id: 10,
      title: 'Chicken Florentine',
      category: 'NON-VEG PIZZA',
      price: 16.99,
      img: chickenFlorentine,
      desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
      button: 'Order Now',
    },
    {
      id: 11,
      title: 'Bailey Scookies',
      category: 'DRINKS & SHAKES',
      price: 16.99,
      img: baileyScookies,
      desc: `Lorem Ipsum is simply dummy text of the printing and typesetting industry`,
      button: 'Order Now',
    },
    
  ];
  export default menu;
  