import React from 'react';
import { NavLink } from 'react-router-dom';
import '../Styles/Register.css';

const Register = () => {
    return (
	<section class="register_user">
    <div class="register">
		<table className="regform">
			<tr>
				<td >
					<h1 className="heading registertitle">Register</h1>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<p>Create your account. It's free and only takes a minute.</p>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<div className="inputreg">
						<input placeholder="First Name" type="text" /> <span>
                            <input placeholder="Last Name"	 type="text" /></span>
					</div>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<div className="inputreg">
						<input type="email" placeholder="Email" /> 
					</div>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<div className="inputreg">
						<input placeholder="Password" type="password" />
					</div>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<div className="inputreg">
						<input placeholder="Confirm Password" type="password" />
					</div>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<div class="terms">
						<input id="checkid2" type="checkbox" value="test" /> 
                        <label for="checkid2">I accept the <a
								href="#">Terms of Use</a> & <a
								href="#">Privacy Policy.</a></label>
					</div>
				</td>
			</tr>
			<tr>
				<td colspan="3">
					<div>
						<input type="submit" value="Register Now" />
					</div>
				</td>
			</tr>
		</table>
		<div class="have_signup">
			<p>Already have an account? <NavLink to="/login">Sign in</NavLink></p>
		</div>
	</div>
	</section>
    )
}

export default Register;
