import React from 'react';
import '../Styles/ChefSpecial.css';
import desert3 from '../images/menu/desert3.jpg';
import pizza1 from '../images/menu/pizza1.jpg';

const ChefSpecial = () => {
    return (
        <div class="chefspecial">
        <section id="gkBottom4">
      
            <div class="boxchef parallax bigtitle gkmod-1"><div class="box-wrap">
                <h1 className="heading chefspecial" >Chef's Special</h1>
                    <div class="content gkPage"><div class="gkNspPM gkNspPM-GridNews" data-cols="2">
                            <figure>
                                <a href="" title="Meat Momo">
                                    <img src={desert3} alt="Meat Momo" />
                                </a>
                                <figcaption>
                                    <div>
                                        <h3>
                                            <a href="#" title="Meat Momo">
                                                Cold Drinks</a>
                                        </h3>
                                        <time datetime="2014-08-11T07:46:54+00:00">$4.95</time><hr class="separator" />
                                        <p>( details of this food could go here ...lorem ipsom .....)</p>
                                        </div></figcaption></figure><figure><a href="#" title="Keema Naan">
                                            <img src={pizza1}  alt="Keema Naan" /></a>
                                            <figcaption><div><h3><a href="" title="Keema Naan">Pizza</a></h3>
                                            <time datetime="2014-07-07T18:00:00+00:00"> $3.50</time><hr class="separator" />
                                            
                                            <p>(details of this food/ ingredients )
                                            (details of this food/ ingredients )
                                            (details of this food/ ingredients )
                                            (details of this food/ ingredients )</p></div>
                                        </figcaption></figure></div></div></div></div>
       
    </section>
    </div>
    )
}

export default ChefSpecial;
