import React from 'react';
import '../Styles/Contact.css';
import RoomIcon from '@mui/icons-material/Room';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const ContactForm = () => {
    return (

<div>
      <section class="section section-contact-info">
    <div class="container">
      <div class="section-inner">
        <div class="contact-info">
          <div class="contact-info-row row">
           <div class="section-contact-form col-lg-6">
              <div class="container">
                <div class="section-inner">
                  <div class="contact-form__wrap max-width--700 margin-auto">
                    <div class="section-head">
                        <h1 class="heading contact">Contact Form</h1>
                        <div class="heading-separator"></div>
                    </div>
                    <div class="section-body">
                      <form action="" method='POST' class="contact-form">
                        <div class="row">
                          <div class="formfield col-12">
                              <label>Full Name</label>
                              <input type="text" name="frmName" placeholder="Full Name" required />
                          </div>
                          {/* <div class="formfield col-md-6">
                              <label>Phone</label>
                              <input type="tel" name="frmPhone" placeholder="Phone Number" required />
                          </div> */}
                          <div class="formfield col-md-6">
                              <label>Email</label>
                              <input type="email" name="frmEmail" placeholder="Your Email" required />
                          </div>
                          <div class="formfield col-md-6">
                              <label>Phone</label>
                              <input type="text" name="frmtel" placeholder="Your Number" required />
                          </div>
                          <div class="formfield col-12">
                              <label>Message</label>
                              <textarea name="frmMessage" placeholder="Your Message"
                                  required></textarea>
                          </div>
                          <div class="col-12">
                              <input type="submit" value="Submit" />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="contact-info-col contact-info-col-text col-lg-6">
                <div class="section-head">
                    <h1 class="heading contact">Contact Info</h1>
                    <div class="heading-separator"></div>
                </div>
                <div class="section-body">
                    <div class="section-cont">
                        <div class="contact-info-list">
                            <div class="contact-info-box">
                                <div class="contact-info-box-icon">
                                    <RoomIcon />
                                </div>
                                <div class="contact-info-box-text">
                                    <strong>Address</strong>
                                    <span>Nepal, Kathmandu</span>
                                </div>
                            </div>
                            <div class="contact-info-box">
                                <div class="contact-info-box-icon">
                                    <LocalPhoneIcon />
                                </div>
                                <div class="contact-info-box-text">
                                    <strong>Call Us Today</strong>
                                    <span><a href="tel:981-810-7201">9818107201</a></span>
                                </div>
                            </div>
                            <div class="contact-info-box">
                                <div class="contact-info-box-icon">
                                    <AccessTimeIcon />
                                </div>
                                <div class="contact-info-box-text">
                                    <strong>Opening Hours</strong>
                                    <span class="timing">Sun - Fri: 10am - 8:00pm </span>
                                </div>
                            </div>
                            <div class="contact-info-box">
                                <div class="contact-info-box-icon">
                                    <MailOutlineIcon />
                                </div>
                                <div class="contact-info-box-text">
                                    <strong>Mail Us:</strong>
                                    <span><a
                                            href="daniel2007@gmail.com">daniel2007@gmail.com</a></span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
             
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="contact-info-col contact-info-col-map ">
    <div class="contact-info-map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3532.063878342879!2d85.32483371501556!3d27.71531393278873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1snaxal-5!5e0!3m2!1sne!2snp!4v1631517132207!5m2!1sne!2snp"
    width="600"
     height="350" 
    style= {{border:0}} 
    allowfullscreen=""
     loading="lazy" />
    </div>
</div>

  </div>
    )
}

export default ContactForm
