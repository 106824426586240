import React from 'react';
import { categories } from "../helpers/data";
import HomeCategoryItem from './HomeCategoryItem';
import '../Styles/HomeCategory.css';

const HomeCategory = () => {
    return (
        <div className="homecategory">
        {categories.map(item=>(
            <HomeCategoryItem item={item} key={item.id}/>  
        ))
        }
    </div>
    )
}

export default HomeCategory;
