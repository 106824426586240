import React,{useState} from 'react';
import '../Styles/Menu.css';
import Categories from '../helpers/Categories';
import items from '../helpers/data';
import MenuList from '../helpers/MenuList';
//import Header from '../components/Header';


const allCategories = ["all", ...new Set(items.map((item) => item.category))];

function Menu() {
  const [menuItems, setMenuItems] = useState(items);
  const [activeCategory, setActiveCategory] = useState("");
  const [categories, setCategories] = useState(allCategories);

  const filterItems = (category) => {
    setActiveCategory(category);
    if (category === "all") {
      setMenuItems(items);
      return;
    }
    const newItems = items.filter((item) => item.category === category);
    setMenuItems(newItems);
  };

  return (
    <>
   
    <main>
      
      <section className="menu section">
        <div className="title">          
          <h1>Menu List</h1>
          <div className="underline"></div>
        </div>

        <Categories
          categories={categories}
          activeCategory={activeCategory}
          filterItems={filterItems}
        />
        <MenuList items={menuItems} />

      </section>
    </main>
    </>
  )
}

export default Menu;
