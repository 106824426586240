
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Special from './pages/Special';
import Menu from './pages/Menu';
import Contact from './pages/Contact';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import SingleMenu from './pages/SingleMenu';
import Register from './pages/Register';
import Login from './pages/Login';
import Cart from './pages/Cart';
import ChooseYourTopping from './pages/ChooseYourTopping';

import jQuery from 'jquery';
window.jQuery = jQuery;
// import 'jquery/jquery';

function App() {
  return (
    <div className="maincontainer">
      <Router>
        <Navbar />
          <Switch>
            <Route path ="/" exact component={Home} />
            <Route path ="/about" component={About} />
            <Route path ="/special" component={Special} />
            <Route path ="/menu" component={Menu} />
            <Route path ="/contact" component={Contact} />
            <Route path="/singlemenu" component={SingleMenu} />
            <Route path="/register" component={Register} />
            <Route path="/login" component={Login} />
            <Route path="/cart" component={Cart} />
            <Route path="/choosetopping" component={ChooseYourTopping} />
          </Switch>
          <Footer />
      </Router>
    </div>
  );
}


export default App;
