import React from 'react';
import desert1 from '../images/menu/desert1.jpg';
import pizza1 from '../images/menu/pizza1.jpg';
import desert2 from '../images/menu/desert2.jpg';
import pizza2 from '../images/menu/pizza2.jpg';
import chocolate from '../images/menu/chocolate.jpg';

const Sidepizza = () => {
    return (
        <div className="sidepizza">
        <div class="section-main">
        <h1 className="heading default">Side Order</h1>

        <div class="row text-center margin-bottom-60">
                <div class="col-md-2 col-sm-4 col-xs-12 md-margin-bottom-50">
                    <img alt="" src={desert1} class={"image-md margin-bottom-20"} />
                    <h5>Choolava Cake </h5>
                    <p>Donec id elit non mi porta gravida at eget metus.</p>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-12 md-margin-bottom-50">
                    <img alt="" src={pizza1} class="image-md margin-bottom-20" />
                    <h5> Pizza Menia </h5>
                    <p>Donec id elit non mi porta gravida at eget metus.</p>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-12 md-margin-bottom-50">
                    <img alt="" src={desert2} class="image-md margin-bottom-20" />
                    <h5>Garlic Bread </h5>
                    <p>Donec id elit non mi porta gravida at eget metus.</p>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-12 md-margin-bottom-50">
                    <img alt="" src={pizza2} class="image-md margin-bottom-20" />
                    <h5>Classic Pizza </h5>
                    <p>Donec id elit non mi porta gravida at eget metus.</p>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-12 md-margin-bottom-50">
                    <img alt="" src={chocolate} class="image-md margin-bottom-20" />
                    <h5>Cold Drink </h5>
                    <p>Donec id elit non mi porta gravida at eget metus.</p>
                </div>
                <div class="col-md-2 col-sm-4 col-xs-12 md-margin-bottom-50">
                    <img alt="" src={desert2} class="image-md margin-bottom-20" />
                    <h5>Garlic Bread </h5>
                    <p>Donec id elit non mi porta gravida at eget metus.</p>
                </div>
            </div>
            </div>
        
    </div>
    )
}

export default Sidepizza
