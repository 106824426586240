import React from 'react';
import Banner from '../components/Banner';
import HomeCategory from '../components/HomeCategory';
//import ClassicPizzas from '../components/classicPizzas';
import Classic from '../components/Classic';
//import SideOrders from '../components/SideOrders';
import Sidepizza from '../components/Sidepizza';
// import OurMenu from '../components/OurMenu';
import ChefSpecial from '../components/ChefSpecial';
import GetInTouch from '../components/GetInTouch';
import Services from '../components/Service';




const Home = () => {
    return (
        <div className="home">
            <Banner />
            <HomeCategory />
            <Classic />
            <Services />
            <Sidepizza />
            <ChefSpecial />
            <GetInTouch />
            
        
        
        
        </div>
    )
}

export default Home;

