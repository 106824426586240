import React from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../components/Button';
// import '../styles/Button.css';



    const MenuList = ({ items }) => {
        return (
          <div className="section-center">
            {items.map((item) => {
              const { id, title, img, desc, price, button } = item;
              return (
                <article key={id} className="menu-item">
                  <img src={img} alt={title} className="photo" />
                  <div className="item-info">
                    <header>
                      <h4>{title}</h4>
                      <h4 className="price">Rs {price}</h4>
                    </header>
                    <p className="item-text">{desc}</p>
                    <NavLink to="/choosetopping" ><Button className={`btn `} >{button}</Button></NavLink>
                  </div>
                </article>
              );
            })}
          </div>
        );
      };
export default MenuList;
