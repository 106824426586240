import React from 'react';
import '../Styles/SingleMenu.css';
import pizza from '../images/menu/breakfast_5.jpg';
import Button from '../components/Button';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

const SingleMenu = () => {
    return (
        <div class="single_menu">

<form class="pizza-builder" action="post" name="pizza-size">
							<div class="container">
								<div class="row">
									<div class="col-md-8">
										<div class="accordion accordion_mod-b panel-group" id="accordion-1">
											<div class="panel panel-default">
												<div class="panel-heading">
													<a class="btn-collapse" data-toggle="collapse" href="#collapse-1" aria-expanded="true"><i class="icon"></i></a>
													<h3 class="panel-title"><span class="panel-title__number">1.</span>Choose Your Crust’s Size</h3>
												</div>
												<div id="collapse-1" class="panel-collapse collapse in" aria-expanded="true" >
													<div class="panel-body">
														<input class="pizza-builder__radio hidden" id="pizza-size_9" type="radio" name="pizza-size" value="9" />
														<label class="pizza-builder__radio-label" for="pizza-size_9">
															<span class="pizza-builder__radio-number pizza-builder__radio-number_9"><span class="pizza-builder__radio-inner">9’’</span></span>
															<span class="pizza-builder__radio-title">Small</span>
														</label>

														<input class="pizza-builder__radio hidden" id="pizza-size_12" type="radio" name="pizza-size" value="12" checked="" />
														<label class="pizza-builder__radio-label" for="pizza-size_12">
															<span class="pizza-builder__radio-number pizza-builder__radio-number_12"><span class="pizza-builder__radio-inner">12’’</span></span>
															<span class="pizza-builder__radio-title">Medium</span>
														</label>

														<input class="pizza-builder__radio hidden" id="pizza-size_14" type="radio" name="pizza-size" value="14" />
														<label class="pizza-builder__radio-label" for="pizza-size_14">
															<span class="pizza-builder__radio-number pizza-builder__radio-number_14"><span class="pizza-builder__radio-inner">14’’</span></span>
															<span class="pizza-builder__radio-title">Large</span>
														</label>

														<input class="pizza-builder__radio hidden" id="pizza-size_16" type="radio" name="pizza-size" value="16" />
														<label class="pizza-builder__radio-label" for="pizza-size_16">
															<span class="pizza-builder__radio-number pizza-builder__radio-number_16"><span class="pizza-builder__radio-inner">16’’</span></span>
															<span class="pizza-builder__radio-title">Jumbo</span>
														</label>

														<input class="pizza-builder__radio hidden" id="pizza-size_18" type="radio" name="pizza-size" value="18" />
														<label class="pizza-builder__radio-label" for="pizza-size_18">
															<span class="pizza-builder__radio-number pizza-builder__radio-number_18"><span class="pizza-builder__radio-inner">18’’</span></span>
															<span class="pizza-builder__radio-title">Party Size</span>
														</label>
													</div>
												</div>
											</div>

											<div class="panel">
												<div class="panel-heading">
													<a class="btn-collapse" data-toggle="collapse" href="#collapse-2"><i class="icon"></i></a>
													<h3 class="panel-title"><span class="panel-title__number">2.</span>Select Your Sauce</h3>
												</div>
												<div id="collapse-2" class="panel-collapse collapse in">
													<div class="panel-body">
														<div class="pizza-builder__wrap-check">
															<input class="pizza-builder__check hidden" id="pizza-sauce_1" type="checkbox" />
															<label class="pizza-builder__item" for="pizza-sauce_1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-ranch.png" height="135" width="135" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Ranch</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">A cool, perfect blend of herbs and spices.</span>
																<span class="pizza-builder__check-price">$1.50</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_2" type="checkbox" checked="" />
															<label class="pizza-builder__item" for="pizza-sauce_2">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-bluecheese.png" height="136" width="135" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Blue Cheese</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Creamy blue cheese packed with flavor in every dip.</span>
																<span class="pizza-builder__check-price">$0.90</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_3" type="checkbox" />
															<label class="pizza-builder__item" for="pizza-sauce_3">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-marinara.png" height="136" width="135" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Marinara</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">All the flavor of Italian pizza sauce, sized for dipping.</span>
																<span class="pizza-builder__check-price">$1.15</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_4" type="checkbox" />
															<label class="pizza-builder__item" for="pizza-sauce_4">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-cheese.png" height="135" width="135" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Cheese</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">A cool, perfect blend of herbs and spices.</span>
																<span class="pizza-builder__check-price">$0.50</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_5" type="checkbox" />
															<label class="pizza-builder__item" for="pizza-sauce_5">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-garlic-butter.png" height="136" width="135" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Garlic</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Put a little zip in your dip with fresh garlic.</span>
																<span class="pizza-builder__check-price">$1.50</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_6" type="checkbox" />
															<label class="pizza-builder__item" for="pizza-sauce_6">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-marinara.png" height="136" width="135" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Barbeque</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">A smoky barbeque sauce made with honey and molasses.</span>
																<span class="pizza-builder__check-price">$0.65</span>
																<i class="icon-check fa fa-check"></i>
															</label>

															<input class="pizza-builder__check hidden" id="pizza-sauce_7" type="checkbox" />
															<label class="pizza-builder__item" for="pizza-sauce_7">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-saunce/sauce-icing.png" height="135" width="135" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Icing</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">A frosty-sweet treat that’s the perfect compliment to pizza.</span>
																<span class="pizza-builder__check-price">$1.30</span>
																<i class="icon-check fa fa-check"></i>
															</label>
														</div>
													</div>
												</div>
											</div>

											<div class="panel">
												<div class="panel-heading">
													<a class="btn-collapse collapsed" data-toggle="collapse" href="#collapse-3"><i class="icon"></i></a>
													<h3 class="panel-title"><span class="panel-title__number">3.</span>Add Cheese</h3>
												</div>
												<div id="collapse-3" class="panel-collapse collapse">
													<div class="panel-body">
														<div class="pizza-builder__wrap-check">
															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-cheese/1.png" height="133" width="180" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Pineapple</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Pineapple, 60g</span>
																<span class="pizza-builder__check-price">$1.50</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a js-active" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-cheese/2.png" height="133" width="180" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Pineapple</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Pineapple, 60g</span>
																<span class="pizza-builder__check-price">$1.50</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div class="panel">
												<div class="panel-heading">
													<a class="btn-collapse collapse" data-toggle="collapse" href="#collapse-4"><i class="icon"></i></a>
													<h3 class="panel-title"><span class="panel-title__number">4.</span>Choose Your Toppings</h3>
												</div>
												<div id="collapse-4" class="panel-collapse collapse in">
													<div class="panel-body">
														<div class="pizza-builder__wrap-check">
															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/1.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Pineapple</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Pineapple, 60g</span>
																<span class="pizza-builder__check-price">$1.50</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/2.png" height="117" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Mushrooms</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Mushrooms, 110g</span>
																<span class="pizza-builder__check-price">$0.45</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/3.png" height="130" width="172" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Spinach</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Spinach, 35g</span>
																<span class="pizza-builder__check-price">$2.20</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a js-active" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/4.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Olives</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Olives, 80g</span>
																<span class="pizza-builder__check-price">$1.00</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/5.png" height="120" width="166" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Hot Chillies</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Hot Chillies, 10g</span>
																<span class="pizza-builder__check-price">$1.15</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/6.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Tiger Shrimps</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Black tiger shrimps, 90g</span>
																<span class="pizza-builder__check-price">$1.05</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/7.png" height="114" width="159" alt="Foto" /> 
																</span>
																<span class="pizza-builder__check-name">Onions</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Onions, 50g</span>
																<span class="pizza-builder__check-price">$0.30</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/8.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Chicken</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Chicken, 100g</span>
																<span class="pizza-builder__check-price">$3.60</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/9.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Broccoli</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Broccoli, 70g</span>
																<span class="pizza-builder__check-price">$1.50</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/10.png" height="118" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Salami</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Mushrooms, 95g</span>
																<span class="pizza-builder__check-price">$2.70</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/11.png" height="115" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Aubergines</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Aubergines, 150g</span>
																<span class="pizza-builder__check-price">$1.45</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/12.png" height="121" width="162" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Peppers</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Peppers, 120g</span>
																<span class="pizza-builder__check-price">$0.80</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/13.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Egg</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Egg, 30g</span>
																<span class="pizza-builder__check-price">$0.25</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/14.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Garlic</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Garlic, 10g</span>
																<span class="pizza-builder__check-price">$0.05</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/15.png" height="114" width="160" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Sweet corn</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Sweet corn, 60g</span>
																<span class="pizza-builder__check-price">$0.60</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/16.png" height="114" width="160" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Bacon</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Bacon, 55g</span>
																<span class="pizza-builder__check-price">$2.85</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/17.png" height="114" width="163" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Ham</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Ham, 65g</span>
																<span class="pizza-builder__check-price">$2.05</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/18.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Seafood</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Seafood, 130g</span>
																<span class="pizza-builder__check-price">$4.60</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/19.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Tuna</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Tuna, 90g</span>
																<span class="pizza-builder__check-price">$0.60</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

															<div class="pizza-builder__item pizza-builder__item_mod-a" tabindex="1">
																<span class="pizza-builder__check-img">
																	<img class="img-responsive center-block" src="assets/media/list-toppings/20.png" height="114" width="159" alt="Foto" />
																</span>
																<span class="pizza-builder__check-name">Artichokes</span>
																<span class="pizza-builder__check-description pizza-builder__check-description_mod-a">Artichokes, 75g</span>
																<span class="pizza-builder__check-price">$1.90</span>
																<select class="selectpicker bs-select-hidden" data-style="ui-select" data-width="58px">
																	<option>1</option>
																	<option>2</option>
																	<option>3</option>
																	<option>4</option>
																	<option>5</option>
																	<option>6</option>
																	<option>7</option>
																</select><div class="btn-group bootstrap-select" style="width: 58px;"><button type="button" class="btn dropdown-toggle ui-select" data-toggle="dropdown" title="1"><span class="filter-option pull-left">1</span>&nbsp;<span class="ui-select__icon"></span></button><div class="dropdown-menu open"><ul class="dropdown-menu inner" role="menu"><li data-original-index="0" class="selected"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">1</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="1"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">2</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="2"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">3</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="3"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">4</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="4"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">5</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="5"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">6</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li><li data-original-index="6"><a tabindex="0" class="" style="" data-tokens="null"><span class="text">7</span><span class="glyphicon glyphicon-ok check-mark"></span></a></li></ul></div></div>
																<a class="pizza-builder__btn-select ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add</a>
																<i class="icon-check fa fa-check"></i>
															</div>

														</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="col-md-4">
										<section class="section-table-order">
											<h2 class="table-order__title ui-title-inner">Your Order</h2>
											<div class="table-container">
												<table class="table-order">
													<thead>
														<tr>
															<th>PRODUCT</th>
															<th>TOTAL</th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Blue Cheese </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">$0.90</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Mozzarella </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">$1.50</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Pineapple </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="2" />
																</div>
															</td>
															<td>
																<span class="table-order__price">$3.00</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Mushrooms </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">$0.45</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Chicken </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">$3.60</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Peppers </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">$0.80</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Garlic </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">$0.05</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td>
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Sweet Corn </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td>
																<span class="table-order__price">$0.60</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td class="border-cell">
																<div class="enumerator">
																	<a class="enumerator__btn enumerator__btn-minus js-minus"><i class="fa fa-minus-circle"></i> </a>
																	<a class="enumerator__btn enumenator__btn-plus js-plus"><i class="fa fa-plus-circle"></i></a>
																	<span class="enumerator__title">Ham </span><span class="color_primary">x</span>
																	<input class="enumerator__input" type="text" placeholder="1" />
																</div>
															</td>
															<td class="border-cell">
																<span class="table-order__price">$2.05</span>
																<i class="icon-close fa fa-times"></i>
															</td>
														</tr>
														<tr>
															<td class="border-cell table-order__base"><b>PIZZA SIZE:</b> Medium 12’’<span class="table-order__size"></span></td>
															<td class="border-cell table-order__base">$8.00</td>
														</tr>
														<tr>
															<td class="table-order__total">Order Total</td>
															<td class="table-order__total">$20.95</td>
														</tr>
													</tbody>
												</table>
											</div>
											<a class="table-order__btn ui-btn ui-btn-primary btn-effect" href="javascript:void(0);">add to cart</a>
											<a class="table-order__btn ui-btn ui-btn_mod-a btn-effect" href="javascript:void(0);">add and create another pizza</a>
										</section>
									</div>
								</div>
							</div>
						</form>






















            {/* <h1 className="heading">Hello</h1>
            <div className="DetailInfo">
                <div  className="singleimage">
                    <img src={pizza} />
                </div>
                <div className="Infotitle">
                    testing testintg
                </div>
                <div className="Infodesc">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                It has survived not only five centuries
                </div>
                <div className="price">$20</div>

                <div className="FilterContainer">
                    <div className="Filter">
                        <div className="FilterTitle">Topping</div>
                        <div class="FilterColor black" ></div>
                        <div class="FilterColor darkblue" ></div>
                        <div class="FilterColor gray" ></div>
                    </div>

                    <div className="filter">
                        <div className="FilterTitle">Size</div>
                        <div className="FilterSize">
                            <select>


                            <option className="FilterSizeOption">XS</option>
                            <option className="FilterSizeOption">XS</option>
                            <option className="FilterSizeOption">XS</option>
                            <option className="FilterSizeOption">XS</option>
                            <option className="FilterSizeOption">XS</option>

                            </select>
                            <div className="AmountContainer">
                                <RemoveOutlinedIcon />
                                    <div className="Amount">1</div>
                                <AddOutlinedIcon />
                            </div>

                            <Button className={`btn `} >Add To Cart</Button>
                        </div>
                    </div>
                </div>

            </div> */}
            
        </div>
    )
}

export default SingleMenu;
